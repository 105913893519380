import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../layout"
import Card from "../../Card/Card"
import MyLink from "../../MyLink/MyLink"
import Breadcrumb from "../../Breadcrumb/Breadcrumb"
import Button from "../../Button/Button"

import he from "he"

const TagsPage = ({ destination }) => {

const data = useStaticQuery(graphql`
query {
  allWordpressTag {
    edges {
      node {
        id
        name
        slug
        wordpress_id
        description
      }
    }
  }
}
`

  )
  const tags = data.allWordpressTag.edges

  return (
    <Layout type="card">
      <Breadcrumb 
        destination={destination} 
        title="Tags"
        />
      {tags.map(tag => (
        <Card
          style="inline"
        >
          <MyLink to={`app/tags/${tag.node.slug}`}>
            <h3>{he.decode(tag.node.name)}</h3>
          </MyLink>
          <p>{tag.node.description}</p>
          <Button>
           <MyLink to={`app/tags/${tag.node.slug}`}><p>Go to tag →</p></MyLink>
          </Button>
        </Card>
      ))}
    </Layout>
  )
}

export default TagsPage