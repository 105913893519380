import React from "react"
import { Link } from "gatsby"
import styles from "./Breadcrumb.module.css"
import _ from "lodash"
import he from "he"

export default function Breadcrumb({ destination, title }){

  const filterCrumbs = (crumb) => {
  
  if (crumb === "app") {
      return false
    } else if (crumb === "") {
      return false
    } else {
      return true
    }
  }

  const crumbs = destination.split("/").filter(filterCrumbs)
  // console.log(crumbs)

  return (

    <div className={styles.wrapper}>
      <Link className={styles.breadcrumb} to="/app/home">Home</Link>
      { " > " }
        {
          crumbs.map( (crumb, i) => {
            //capitalize each
            const capitalCrumb = _.startCase(crumb)

            //keep track of the current location to link to the right place
            let location = "/app/" + crumbs[0] + "/"
            if (i >= 1) {
              location = location.concat(crumbs[i] + "/")
            }

          return [
            i !== crumbs.length - 1 ? (
           <Link className={styles.breadcrumb} to={location}>
            {capitalCrumb}
          </Link>
            ) : (
              <p>{he.decode(title)}</p>
            ),
            crumbs.length - 1 !== i && " > ",
          ]})
          }
    </div>
    )

  // return(
  //   <div className={styles.wrapper}>
  //     {
  //       crumbs.map( (crumb, i) => {

  //         const capitalCrumbLabel = _.startCase(
  //           crumb.crumbLabel.replace("Root", "Home")
  //         )

  //         return [
  //           i !== crumbs.length - 1 ? (
  //             <Link className={styles.breadcrumb} to={crumb.pathname}>
  //               {capitalCrumbLabel}
  //             </Link>
  //           ) : (
  //             <p>{capitalCrumbLabel}</p>
  //           ),
  //           crumbs.length - 1 !== i && " > ",
  //         ]})
  //     }
  //   </div>
  // )
}