import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../layout"
import Card from "../../Card/Card"
import MyLink from "../../MyLink/MyLink"
import Breadcrumb from "../../Breadcrumb/Breadcrumb"
import Button from "../../Button/Button"

const CategoriesPage = ({ destination }) => {

  const data = useStaticQuery(graphql`
  query {
    allWordpressCategory(filter: {slug: {ne: "announcements"}}) {
      edges {
        node {
          id
          name
          wordpress_id
          slug
          description
        }
      }
    }
  }
`
)

  const categories = data.allWordpressCategory.edges

  return (
    <Layout type="card">
      <Breadcrumb 
        destination={destination} 
        title="Categories"
        />
      {categories.map(category => (
        <Card
          style="inline"
        >
          <MyLink to={`/app/categories/${category.node.slug}`}><h3>{category.node.name}</h3></MyLink>
          <p>{category.node.description}</p>
        <Button>
          <MyLink to={`/app/categories/${category.node.slug}`}><p>Go to category →</p></MyLink>
        </Button>
        </Card>
      ))}
    </Layout>
  )
}

export default CategoriesPage