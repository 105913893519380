import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../layout"
import MyLink from "../../MyLink/MyLink"
import Breadcrumb from "../../Breadcrumb/Breadcrumb"
import he from "he"

const LessonTemplate = ({ id, destination }) => {

  const data = useStaticQuery(graphql`
    query {
      allWordpressPost(sort: {fields: date, order: DESC}){
        nodes {
          categories {
            name
            slug
            wordpress_id
          }
          content
          date(formatString: "MMMM DD, YYYY")
          tags {
            wordpress_id
            name
            slug
          }
          id
          wordpress_id
          title
        }
      }
    }
  `)

  const lessons = data.allWordpressPost.nodes
  const lesson =  lessons.find(lesson => lesson.id === id)

  return (
    <Layout type="text">
      <Breadcrumb 
        destination={destination} 
        title={lesson.title}
        />
      <h1>
        {he.decode(lesson.title)}
      </h1>
      <p>{lesson.date}</p>
      <div dangerouslySetInnerHTML={{ __html: lesson.content }} />
      <div>
        <p>
          {lesson.tags ? (
            <p>
              {"Tags on this lesson: "}
              {lesson.tags.map((tag, i) => {
                return [
                  <MyLink to={`app/tags/${tag.slug}`}>{he.decode(tag.name)}</MyLink>,
                  lesson.tags.length - 1 !== i && " / ",
                ]
              })}
            </p>
          )
          : ""
          }
        </p>
      </div>
    </Layout>
  )
}

export default LessonTemplate