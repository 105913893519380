import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../layout"
import Breadcrumb from "../Breadcrumb/Breadcrumb"

const PageTemplate = ({ id, destination }) => {

  const data = useStaticQuery(graphql`
  query{
  allWordpressPage {
    nodes {
      title
      content
      date
      slug
      wordpress_id
      id
    }
  }
}
`)

  const pages = data.allWordpressPage.nodes
  const page = pages.find(page => page.id === id)

  return (
    <Layout type="text">
      <Breadcrumb 
        destination={destination} 
        title={page.title}
        />
      <div dangerouslySetInnerHTML={{ __html: page.content }} />
    </Layout>
  )
}

export default PageTemplate