import React from "react"
import { graphql } from "gatsby"
import { Router } from "@reach/router"
import PrivateRoute from "../components/Pages/PrivateRoute"
import Login from "../components/Pages/LogIn/LogIn"
import TagsPage from "../components/Pages/Tags/TagsPage"
import TagsTemplate from "../components/Pages/Tags/TagsTemplate"
import CategoriesPage from "../components/Pages/Categories/CategoriesPage"
import CategoriesTemplate from "../components/Pages/Categories/CategoriesTemplate"
import LessonTemplate from "../components/Pages/Lesson/LessonTemplate"
import PageTemplate from "../components/Pages/PageTemplate"
import HomePage from "../components/Pages/HomePage/HomePage"

const App = ({ data }) => {

  const Tags = data.allWordpressTag.edges
  const Categories = data.allWordpressCategory.edges
  const Lessons = data.allWordpressPost.edges
  const Pages = data.allWordpressPage.edges

  return (
    <>
    <Router>
      <PrivateRoute path="/app/home" component={HomePage} destination="/app/home" />
      <Login path="/app/login" />
      <PrivateRoute path="/app/tags" component={TagsPage} destination="/app/tags"/>
      { Tags.map(tag => (
        <PrivateRoute 
          key={tag.node.id}
          id={tag.node.id} 
          wordpressId={tag.node.wordpress_id} 
          name={tag.node.name}
          path={`/app/tags/${tag.node.slug}`} 
          component={TagsTemplate} 
          destination={`/app/tags/${tag.node.slug}`} 
          />
      ))}

      <PrivateRoute path="/app/categories" component={CategoriesPage} destination="/app/categories" />
      {
        Categories.map(category => (
          <PrivateRoute
            key={category.node.id}
            id={category.node.id}
            wordpressId={category.node.wordpress_id}
            name={category.node.name}
            path={`/app/categories/${category.node.slug}`}
            destination={`/app/categories/${category.node.slug}`}
            component={CategoriesTemplate}
            />
        ))
      }
      {
        Lessons.map(lesson => (
          <PrivateRoute
            key={lesson.node.id}
            id={lesson.node.id}
            wordpressId={lesson.node.wordpress_id}
            path={`/app/categories/${lesson.node.categories[0].slug}/${lesson.node.slug}`}
            destination={`/app/categories/${lesson.node.categories[0].slug}/${lesson.node.slug}`}
            component={LessonTemplate}
          />
        ))
      }
      {
        Pages.map(page => (
          <PrivateRoute
            key={page.node.id}
            id={page.node.id}
            wordpressId={page.node.wordpress_id}
            path={`/app/${page.node.slug}`}
            destination={`/app/${page.node.slug}`}
            component={PageTemplate}
          />
        ))
      }
    </Router>
    </>
  )
}

export default App

export const query = graphql`
      query MyQuery {
        allWordpressPage {
          edges {
            node {
              slug
              id
              wordpress_id
            }
          }
        }
        allWordpressPost {
          edges {
            node {
              slug
              id
              wordpress_id
              categories {
                slug
                wordpress_id
              }
            }
          }
        }
        allWordpressTag {
          edges {
            node {
              name
              wordpress_id
              slug
              id
            }
          }
        }
        allWordpressCategory {
          edges {
            node {
              name
              id
              slug
              wordpress_id
            }
          }
        }
      }
    `