import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../layout"
import Card from "../../Card/Card"
import Breadcrumb from "../../Breadcrumb/Breadcrumb"
import MyLink from "../../MyLink/MyLink"
import Button from "../../Button/Button"

import he from "he"
import striptags from "striptags"

const TagsTemplate = ( { name, id, destination } ) => {

  const data = useStaticQuery(graphql`
  query{  
    allWordpressPost(sort: {fields: date, order: DESC}){
        nodes {
          tags {
            name
            id
            wordpress_id
          }
          title
          content
          date(formatString: "MMM DD, YYYY")
          excerpt
          slug
          categories {
            slug
          }
        }
      }
    }
`)

  const lessons = data.allWordpressPost.nodes

  const lessonsWithTags = lessons.filter(lesson => lesson.tags)

  const lessonsInTag = lessonsWithTags.filter(lessonWithTag => JSON.stringify(lessonWithTag.tags).includes(id))

  //TODO create excerpt component
  const autoExcerpt = html => {
    const chars = 300
    const cleanText = he.decode(striptags(html))
    const truncatedExcerpt = cleanText.substring(0, chars)
    return truncatedExcerpt + "..."
  }

  return (
    <Layout type="card">
      <Breadcrumb 
        destination={destination} 
        title={name}
        />
      {
        lessonsInTag.map(lessonInTag => {

          const wordpressDefault = lessonInTag.excerpt.includes("wordpress")

          return (
            <Card>
                <MyLink
                  to={`/app/categories/${lessonInTag.categories[0].slug}/${lessonInTag.slug}`}
                >
              <h3>
                  {he.decode(lessonInTag.title)}
              </h3>
                </MyLink>
              <h4>{lessonInTag.date}</h4>
              {!wordpressDefault ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: lessonInTag.excerpt,
                  }}
                />
              ) : (
                  <p>{autoExcerpt(lessonInTag.content)}</p>
                )}
              <Button>
                <MyLink
                  to={`/app/categories/${lessonInTag.categories[0].slug}/${lessonInTag.slug}`}
                >
                  <p>Go to lesson →</p>
                </MyLink>
              </Button>
            </Card>
          )
        })
      }
    </Layout>
  )
}

export default TagsTemplate
