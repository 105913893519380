import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../layout"
import Card from "../../Card/Card"
import MyLink from "../../MyLink/MyLink"
import Breadcrumb from "../../Breadcrumb/Breadcrumb"
import Button from "../../Button/Button"

import he from "he"
import striptags from "striptags"

const CategoriesTemplate = ({ name, wordpressId, destination }) => {

  const data = useStaticQuery(graphql`
  query {
    allWordpressPost(sort: {fields: date, order: DESC}){
        nodes {
          categories {
            name
            slug
            wordpress_id
          }
          content
          date(formatString: "MMM DD, YYYY")
          title
          slug
          excerpt
        }
      }
    }
`)

  const lessons = data.allWordpressPost.nodes
  const lessonsInCategory = lessons.filter(lessonInCategory => JSON.stringify(lessonInCategory.categories).includes(wordpressId))
  console.log(lessonsInCategory)

  const autoExcerpt = (html) => {
    const chars = 300;
    const cleanText = he.decode(striptags(html))
    const truncatedExcerpt = cleanText.substring(0, chars)
    return truncatedExcerpt + "..."
  }

  return (
    <Layout type="card">
      <Breadcrumb 
        destination={destination} 
        title={name}
        />
      {lessonsInCategory.map(lessonInCategory => {
        const wordpressDefault = lessonInCategory.excerpt.includes("wordpress")
        return (
          <Card>
            <MyLink
              to={`/app/categories/${lessonInCategory.categories[0].slug}/${lessonInCategory.slug}`}
            >
              <h3>
                {he.decode(lessonInCategory.title)}
              </h3>
            </MyLink>
            <h4>{lessonInCategory.date}</h4>
            {!wordpressDefault ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: lessonInCategory.excerpt,
                }}
              />
            ) : <> <p>{autoExcerpt(lessonInCategory.content)}</p> </>}
            <Button>
              <MyLink
                to={`/app/categories/${lessonInCategory.categories[0].slug}/${lessonInCategory.slug}`}
              >
                <p>Go to lesson →</p>
            </MyLink>
            </Button>
          </Card>
        )
      }
      )}
    </Layout>

  )
}

export default CategoriesTemplate